import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/Blog/Card"
import { D1, D3 } from "../components/utils/Typography"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

import HeroImage from "../images/blogHero.jpg"
import SideCard from "../components/Blog/SideCard"
import OnDeltaCard from "../components/Website-Blocks/OnDeltaCard"
import Email from "../components/Website-Blocks/Email"

const HeroCover = styled.div`
  background-color: #12141678;
  height: 60vh;
  padding: 160px 80px;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
`

const Hero = styled.div`
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`

const Header = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  padding: 20px;
`

const BlogPosts = ({ data }) => {
  const blogPosts = data.allContentfulBlog.edges
  return (
    <Layout>
      <SEO title="Blog" />

      <Hero style={{ backgroundImage: `url(${HeroImage})` }}>
        <HeroCover>
          <Header>
            <D1 style={{ color: "white" }}>JGR Blog</D1>
            <D3
              style={{
                color: "white",
                fontWeight: "700",
              }}
            >
              Content for the remote community.
            </D3>
          </Header>
        </HeroCover>
      </Hero>

      <Container>
        <Row>
          <Col lg="9">
            {" "}
            <div>
              {blogPosts.map(({ node: post }) => (
                <div key={post.id}>
                  <Link
                    to={`/blog/${post.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      title={post.title}
                      image={post.image.file.url}
                      preview={post.preview.preview}
                      author={`By: ${post.author}`}
                    />
                  </Link>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={{ size: 3 }}>
            <SideCard />
            <OnDeltaCard />
          </Col>
        </Row>
        <Email />
      </Container>
    </Layout>
  )
}

export default BlogPosts

export const query = graphql`
  query BlogPostsPageQuery {
    allContentfulBlog(limit: 1000) {
      edges {
        node {
          id
          title
          author
          slug
          preview {
            preview
          }
          body {
            body
          }
          image {
            file {
              url
            }
          }
          tags
        }
      }
    }
  }
`
