import React from "react"
import styled from "styled-components"
import { H4, H5, P } from "../utils/Typography"
import { Container, Row, Col } from "reactstrap"

const CardMain = styled.div`
  background-color: white;
  min-height: 200px;
  width: 100%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  margin: 30px 0;
`
const Content = styled.div`
  padding: 30px;
  color: #121416;
  text-decoration: none;
`

export default class Card extends React.Component {
  render() {
    return (
      <CardMain>
        <Container style={{ paddingLeft: "0", height: "250px" }}>
          <Row noGutters style={{ height: "100%" }}>
            <Col
              lg="4"
              md="4"
              sm="3"
              xs="4"
              style={{
                backgroundImage: `url(${this.props.image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
              }}
            />
            <Col>
              <Content>
                <H4 style={{ marginBottom: "10px" }}>{this.props.title}</H4>
                <H5
                  style={{
                    color: "gray",
                    marginBottom: "20px",
                    fontWeight: "600",
                  }}
                >
                  {this.props.author}
                </H5>
                <P style={{ fontSize: "14px", marginBottom: "15px" }}>
                  {this.props.preview}
                </P>
              </Content>
            </Col>
          </Row>
        </Container>
      </CardMain>
    )
  }
}
