import React from "react"
import styled from "styled-components"
import { Container } from "reactstrap"
import { H4, D2 } from "../utils/Typography"
import { ButtonPrimary } from "../utils/Buttons"

const Main = styled.div`
  background-color: #0022e721;
  padding: 50px 20px 50px 20px;
  margin: 30px 0;
`

export default class SideCard extends React.Component {
  render() {
    return (
      <Main>
        <Container>
          <D2 style={{ textAlign: "center", marginBottom: "20px" }}>JGR</D2>
          <H4 style={{ textAlign: "center", fontWeight: "500" }}>
            Want to reach thousands of marketers?
          </H4>
          <a href="/postJob" target="_blank" rel="noopener noreferrer">
            <ButtonPrimary style={{ fontSize: "15px", marginTop: "20px" }}>
              {" "}
              Post a Job for $10/day
            </ButtonPrimary>
          </a>
        </Container>
      </Main>
    )
  }
}
